// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";



import { environment } from './environments/environment';
import { ErrorHandler/*, APP_INITIALIZER*/, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
// import { Router } from '@angular/router';
// import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { /*provideClientHydration, BrowserModule,*/ bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatIconModule } from '@angular/material/icon';
// import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';

// {
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: [
//   //   environment.paymentsBaseUrl,
//   //   environment.usersBaseUrl,
//   //   environment.eventsBaseUrl,
//   //   environment.photosBaseUrl,
//   //   environment.notificationsBaseUrl,
//   // ],
//   routingInstrumentation: Sentry.routingInstrumentation,
// }

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.instrumentOutgoingRequests,
  ],
  // Performance Monitoring
  tracesSampleRate: environment.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: environment.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      // BrowserModule, 
      AppRoutingModule,
      // MatProgressBarModule, MatSnackBarModule, MatProgressSpinnerModule, MatIconModule,
        // NgxErrorPagesModule.forRoot({
        //     errorPage: {
        //         backText: 'home',
        //         backURL: '../',
        //         description: 'There was an error displaying this page',
        //         heading: '500 Page Error',
        //     },
        //     notFoundPage: {
        //         backText: 'home',
        //         backURL: '../',
        //         description: 'This link does not exist',
        //         heading: '404 Page Not Found',
        //     },
        // }),
        /*NgxNavigationAnimationModule*/
      ),
    // {
    //     provide: HOST,
    //     useExisting: typeof window === 'undefined' ? undefined : window.location.host
    // },
    // {
    //     provide: PATH,
    //     useExisting: typeof window === 'undefined' ? undefined : window.location.pathname
    // },
    // {
    //     provide: PROTOCOL,
    //     useExisting: typeof window === 'undefined' ? undefined : window.location.protocol
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => { },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    provideAppInitializer(async () => inject(Sentry.TraceService)),
    // provideHttpClient(withInterceptorsFromDi(), withFetch()),
  ]
})
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
