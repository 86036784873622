import { mediaType, Photo } from "@pixomaticc/ngx-ui";
import EventPhotosUserMatchPhotoIdMatchesInterface from "../types/event-photos-user-match-photo-id-matches.interface";
// import { ObjectId } from "mongodb";

let mongodb = {} as any;

try {
    mongodb = require('mongodb')
} catch (e) {
    // console.error(e);
}

const PHOTO_MATCHES_PER_PAGE: number = 12;

const filterPhotosById
  = (
    photoIds: string[] | undefined,
    photos: Photo[] | undefined
  ): Photo[] | undefined => photoIds && photos?.filter(({ _id }) => photoIds.includes(_id));

export default async (
  // contactId: string,
  // eventId: string,
  photos: Photo[],
  allEventPhotoIdMatches: EventPhotosUserMatchPhotoIdMatchesInterface[],
  coverPhoto: Photo,
  isCollaborationEvent: boolean,
  isFreeEvent: boolean,
  disallowViewMediaBeforePayment: boolean,
  userProfilePhotoId?: string[],
  coverPhotoId?: string,
): Promise<{ cover?: Pick<Photo, 'width' | 'height' | 'highResURL' | 'midResURL' | 'thumbURL' | 'lowResURL'>, photoMatches: (Photo & Pick<EventPhotosUserMatchPhotoIdMatchesInterface, 'comments' | 'selected'> & { isSelected?: boolean })[] | undefined, profilePhotos: { url: string, id: string }[] | undefined }> => {
  // fetch photoId matches for the first page
  // together with the paid/selected, comments, downloads information
  // fetch everything at first just so we can check if everything is paid in disallowViewMediaBeforePayment condition
  const totalEventPhotoIdMatches
    = allEventPhotoIdMatches /*await fetchPhotoMatches(contactId, eventId)*/;

  const eventPhotoIdMatches: EventPhotosUserMatchPhotoIdMatchesInterface[]
    = totalEventPhotoIdMatches.slice(0, PHOTO_MATCHES_PER_PAGE);

  const galleryPhotoIdsCommentsPaymentAndDownloadInfo
    : (Omit<EventPhotosUserMatchPhotoIdMatchesInterface, '_id' | 'selected'> & { isSelected?: boolean })[]
    = eventPhotoIdMatches.map(
      ({ selected, _id, ...others }) =>
      (
        {
          ...others,
          isSelected: !!selected
        }
      ),
    );

  const galleryPhotoIds: string[] = galleryPhotoIdsCommentsPaymentAndDownloadInfo.map(({ photoId }) => photoId)

  const photoIdsToFetch: string[] = [
    ...new Set(
      [
        ...(userProfilePhotoId || []),
        ...(galleryPhotoIds || []),
        ...(!!coverPhotoId ? [coverPhotoId] : [])
      ],
    ),
  ];

  if (!!photoIdsToFetch.length) {
    // const photos: Photo[] | undefined = await getPhotosByIds(photoIdsToFetch);

    const galleryPhotos: Photo[] | undefined
      = filterPhotosById(galleryPhotoIds, photos);

    const profilePhotos: { url: string, id: string }[] | undefined
      = filterPhotosById(userProfilePhotoId, photos)
        ?.map(({ highResURL, _id: id }) => ({ url: highResURL!, id }));

    const returnNoGalleryPhotos = () => [
      {
        _id: '',
        name: '',
        highResURL: undefined,
        hlsPlaylistURLs: undefined,
        hlsSegmentURLs: undefined,
        hlsURL: undefined,
        height: 0,
        width: 0,
        timestamp: Date.now(),
        type: mediaType.photo,
        mimetype: 'image/jpg',
        createdAt: new Date(),
      },
    ];
    const returnAllGalleryPhotos
      = (photoIdsToHideHighResUrls?: string[], hideAllHighResUrls?: boolean) =>
        galleryPhotos?.map(
          (photo: Photo) =>
          (
            {
              ...photo,
              ...(
                galleryPhotoIdsCommentsPaymentAndDownloadInfo.find(
                  ({ photoId }) => new mongodb.ObjectId(photoId).equals(photo._id)
                )
                ?? {}
              ),
              photoId: undefined,
              ...(
                !!hideAllHighResUrls
                  ? ({ highResURL: undefined, hlsURL: undefined, hlsPlaylistURLs: undefined, hlsSegmentURLs: undefined })
                  : (
                    !!photoIdsToHideHighResUrls?.length
                      ? (
                        photoIdsToHideHighResUrls.find(
                          (photoId: string) => new mongodb.ObjectId(photoId).equals(photo._id)
                        ) ? { highResURL: undefined, hlsURL: undefined, hlsPlaylistURLs: undefined, hlsSegmentURLs: undefined } : {}
                      )
                      : {}
                  )
              ),
            }
          ),
        );

    let cover: Pick<Photo, 'width' | 'height' | 'highResURL' | 'midResURL' | 'thumbURL' | 'lowResURL'> | undefined;
    let photoMatches: (Photo & Pick<EventPhotosUserMatchPhotoIdMatchesInterface, 'comments' | 'selected'> & { isSelected?: boolean })[] | undefined;

    if (isCollaborationEvent) {
      // is collaboration event

      photoMatches = returnAllGalleryPhotos(undefined, true);
    } else if (isFreeEvent) {
      // is free event
      photoMatches = returnAllGalleryPhotos();
    } else if (disallowViewMediaBeforePayment) {
      // total amount on a gallery is preferred over pay per picture, hence the condition comes first
      // is paid event and previews are disabled

      //If atleast one is unpaid, then dont show anything
      if (totalEventPhotoIdMatches.some(({ status }) => status !== 'paid')) {
        photoMatches = returnNoGalleryPhotos();
      } else {
        // everything paid so return everything
        photoMatches = returnAllGalleryPhotos();
      }
    } else {
      // paid event and previews are enabled or is pay per picture
      photoMatches = returnAllGalleryPhotos(
        eventPhotoIdMatches
          .filter(({ status }) => status !== 'paid')
          .map(({ photoId }) => photoId)
      );
    }

    // decide cover photo
    if (!!coverPhotoId) {
      cover = coverPhoto/*photos?.find(({ _id }) => new Types.ObjectId(coverPhotoId).equals(_id))*/;
    } else {
      cover = photos?.reduce(
        (acc, { highResURL = '', midResURL = '', width = 0, height = 0, thumbURL = '', lowResURL = '' }) =>
          width > acc.width ? { highResURL, midResURL, width, height, thumbURL, lowResURL } : acc,
        { width: 0, height: 0, highResURL: '', midResURL: '', thumbURL: '', lowResURL: '' },
      );
    }

    return {
      cover,
      photoMatches,
      profilePhotos,
    }
  } else {
    return { cover: undefined, photoMatches: undefined, profilePhotos: undefined };
  }
}