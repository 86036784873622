enum galleryTypography {
    sans = 'sans',
    serif = 'serif',
    stylish = 'stylish',
    classic = 'classic',
    strong = 'strong',
    sleek = 'sleek'
}

export default galleryTypography;
