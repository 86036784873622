// import { Collection, Document, ObjectId } from "mongodb";
import { Photo } from "@pixomaticc/ngx-ui";
import GalleryData from "../types/gallery-data.type";
import transformS3UrlToCloudfrontUrl from "../utils/transform-s3-url-to-cloudfront-url";
import Utils from "@uzenith360/utils";

let mongodb = {} as any;

try {
    mongodb = require('mongodb')
} catch (e) {
    // console.error(e);
}

const transformS3PhotoURLToCloudfrontURL = (photo?: Photo): Photo | undefined => {
    return photo && {
        ...photo,
        lowResURL: transformS3UrlToCloudfrontUrl(photo.lowResURL),
        midResURL: transformS3UrlToCloudfrontUrl(photo.midResURL),
        highResURL: transformS3UrlToCloudfrontUrl(photo.highResURL),
        thumbURL: transformS3UrlToCloudfrontUrl(photo.thumbURL),
        previewURL: transformS3UrlToCloudfrontUrl(photo.previewURL),
        hlsURL: transformS3UrlToCloudfrontUrl(photo.hlsURL),
        hlsPlaylistURLs: photo.hlsPlaylistURLs
            ?.map(transformS3UrlToCloudfrontUrl)
            ?.filter<string>((url: string | undefined): url is string => !!url),
        hlsSegmentURLs: photo.hlsSegmentURLs
            ?.map(transformS3UrlToCloudfrontUrl)
            ?.filter<string>((url: string | undefined): url is string => !!url)
    }
}

export default async (
    eventCollection: /*Collection<Document>*/any,
    eventId: string,
    contactId: string,
    photosLimit: number,
    setIndex?: number,): Promise<GalleryData | undefined> => {
    const result = await eventCollection.aggregate(
        [
            {
                $match: {
                    _id: new mongodb.ObjectId(eventId),
                },
            },
            {
                $set: {
                    contactId: new mongodb.ObjectId(contactId),
                },
            },
            {
                $lookup: {
                    from: "event-photos-user-matches",
                    let: {
                        eventId: "$_id",
                        contactId: "$contactId",
                    },
                    pipeline: [
                        {
                            $match: {
                                $expr: {
                                    $and: [
                                        {
                                            $eq: ["$eventId", "$$eventId"],
                                        },
                                        {
                                            $eq: [
                                                "$contactId",
                                                "$$contactId",
                                            ],
                                        },
                                    ],
                                },
                            },
                        },
                        ...(
                            setIndex !== undefined
                                ? [
                                    {
                                        $set: {
                                            set: {
                                                $arrayElemAt: ["$sets", setIndex]
                                            }
                                        }
                                    },
                                    {
                                        $set: {
                                            photoIdMatches: "$set.filesData"
                                        }
                                    },
                                    { $unset: ["set", "sets"] },
                                ]
                                : []
                        ),
                        {
                            $set: {
                                photoIdMatchesCount: { $size: "$photoIdMatches" },
                                photoIdMatches: {
                                    $slice: ["$photoIdMatches", photosLimit],
                                },
                            },
                        },
                    ],
                    as: "photoMatches",
                },
            },
            {
                $lookup: {
                    from: "photos",
                    localField:
                        "photoMatches.photoIdMatches.photoId",
                    foreignField: "_id",
                    as: "photos",
                },
            },
            {
                $lookup: {
                    from: "photos",
                    localField: "coverPhotoId",
                    foreignField: "_id",
                    as: "coverPhoto",
                },
            },
            {
                $lookup: {
                    from: "contacts",
                    localField: "contactId",
                    foreignField: "_id",
                    as: "contact",
                },
            },
            {
                $lookup: {
                    from: "photographers",
                    localField: "photographerId",
                    foreignField: "_id",
                    as: "photographer",
                },
            },
            {
                $lookup: {
                    from: "users",
                    localField: "photographer.userId",
                    foreignField: "_id",
                    pipeline: [
                        { $project: { hash: 0, hashs: 0 } }
                    ],
                    as: "photographerUser",
                }
            },
            {
                $lookup: {
                    from: "bank-accounts",
                    localField: "photographer.bankAccountId",
                    foreignField: "_id",
                    as: "bankAccount",
                },
            },
            {
                $lookup: {
                    from: "offline-payments",
                    let: {
                        eventId: "$_id",
                        contactId: "$contactId",
                        status: "pending",
                    },
                    pipeline: [
                        {
                            $match: {
                                $expr: {
                                    $and: [
                                        {
                                            $eq: ["$eventId", "$$eventId"],
                                        },
                                        {
                                            $eq: [
                                                "$contactId",
                                                "$$contactId",
                                            ],
                                        },
                                        {
                                            $eq: ["$status", "$$status"],
                                        },
                                    ],
                                },
                            },
                        },
                        {
                            $set: {
                                amount: { $toDouble: "$amount" },
                            },
                        },
                    ],
                    as: "offlinePayment",
                },
            },
            {
                $project: {
                    photographerId: 1,
                    coverPhotoId: 1,
                    name: 1,
                    isClientDownloaded: 1,
                    isClientReviewed: 1,
                    isClientSelected: 1,
                    isSkipClientReview: 1,
                    isViewPhotosBeforePayment: 1,
                    isOfflinePayment: 1,
                    galleryEventDate: 1,
                    currency: 1,
                    updatedAt: 1,
                    createdAt: 1,
                    action: 1,
                    mode: 1,
                    isLandingPageGallery: 1,

                    totalAmount: {
                        $toDouble: "$totalAmount",
                    },
                    amount: {
                        $toDouble: "$amount",
                    },
                    code: 1,
                    photoMatches: {
                        $first: "$photoMatches",
                    },
                    photos: 1,
                    coverPhoto: {
                        $first: "$coverPhoto",
                    },
                    photographerUser: {
                        $first: "$photographerUser",
                    },
                    contact: {
                        $first: "$contact",
                    },
                    photographer: {
                        $first: "$photographer",
                    },
                    bankAccount: {
                        $first: "$bankAccount",
                    },
                    offlinePayment: {
                        $first: "$offlinePayment",
                    },
                    ...[
                        'allowComments',
                        'allowFavorites',
                        'allowFrameRequests',
                        'allowPhotobookRequests',
                        'allowPrintRequests',
                        'allowUpsells',
                        'galleryCoverStyle',
                        'downloadResolution',
                        'galleryColor',
                        'galleryRoundness',
                        'gallerySize',
                        'gallerySpacing',
                        'galleryTypography',
                        'downloadPIN',
                        'allowSingleFileDownloads',
                        'allowFileDownloadEmailTracking'
                    ].reduce((acc, field) => ({ ...acc, [field]: 1 }), {}),
                },
            },
        ]
    ).toArray();

    const galleryData = result?.[0] as GalleryData | undefined;

    if (!!galleryData) {
        return {
            ...galleryData,
            photographer: {
                ...galleryData.photographer,
                paymentInstructions: galleryData.photographer.paymentInstructions
                    && Utils.toSentenceCase(galleryData.photographer.paymentInstructions)
            },
            photos: galleryData?.photoMatches?.photoIdMatches?.map(
                ({ photoId }) => transformS3PhotoURLToCloudfrontURL(
                    galleryData?.photos?.find(({ _id }) => new mongodb.ObjectId(_id).equals(photoId))
                ),
            )?.filter(v => !!v),
            coverPhoto: transformS3PhotoURLToCloudfrontURL(galleryData.coverPhoto),
        }
    } else {
        return galleryData;
    }
}
