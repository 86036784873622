// import { Collection, Document } from "mongodb";
import Utils from '@uzenith360/utils';
import { Photographer } from "@pixomaticc/ngx-ui";
import EventInterface from "../types/event.interface";

export default async (eventCollection: /*Collection<Document>*/any, subdomain: string, slug: string): Promise<EventInterface & { photographer: Photographer } | undefined> => {
    const result = await eventCollection.aggregate(
        [
            { $match: { slug: Utils.trimAndLowercase(slug) } },
            {
                $lookup: {
                    from: "photographers",
                    localField: "photographerId",
                    foreignField: "_id",
                    as: "photographer"
                }
            },
            { $unwind: "$photographer" },
            { $match: { "photographer.subdomain": Utils.trimAndLowercase(subdomain) } },
            {
                $addFields: {
                    amount: { $toDouble: '$amount' },
                    totalAmount: { $toDouble: '$totalAmount' }
                }
            },
        ],
    ).toArray();

    return result?.[0] as EventInterface & { photographer: Photographer };
}
