import { GalleryColor } from "@pixomaticc/ngx-ui";

const galleryColorList: GalleryColor[] = [
    {
        title: 'light',
        background: '#FFFFFF',
        active: '#000000', 
        accent: '#643030',
        button: '#333333',
    }, {
        title: 'coastal sunrise',
        background: '#AEEFF0',
        active: '#000000', 
        accent: '#8E565F',
        button: '#007B8F',
    }, {
        title: 'beachside escape',
        backgroundDirection: 'diagonal-bottom-right',
        fromBackground: '#FFFFFF',
        toBackground: '#F1D1B5',
        active: '#000000', 
        accent: '#356A58',
        button: '#002550',
    }, {
        title: 'lavender dreams',
        background: '#EDE8F6',
        active: '#000000', 
        accent: '#504182',
        button: '#3C3B6E',
    }, {
        title: 'sunset radiance',
        backgroundDirection: 'diagonal-bottom-left',
        fromBackground: '#FFEBBB',
        toBackground: '#FFC371',
        active: '#000000', 
        accent: '#6c500e',
        button: '#FF4500',
    }, {
        title: 'forest haven',
        background: '#9BC995',
        active: '#000000', 
        accent: '#246208',
        button: '#595845',
    }, {
        title: 'fresh mint',
        backgroundDirection: 'diagonal-bottom-right',
        fromBackground: '#DDF9E2',
        toBackground: '#B4DAE5',
        active: '#000000', 
        accent: '#665975',
        button: '#006771',
    }, {
        title: 'modern minimalist',
        background: '#F0F0F0',
        active: '#000000', 
        accent: '#7D4040',
        button: '#4C5C6E',
    }, {
        title: 'nautical voyage',
        backgroundDirection: 'top-to-bottom',
        fromBackground: '#E0FFFF',
        toBackground: '#00274D',
        active: '#000000', 
        accent: '#358270',
        button: '#000033',
    }, {
        title: 'plum romance',
        background: '#F8E1E7',
        active: '#000000', 
        accent: '#803763',
        button: '#FF2081',
    }, {
        title: 'sweet harmony',
        backgroundDirection: 'diagonal-bottom-left',
        fromBackground: '#FFDAB9',
        toBackground: '#FFCCD4',
        active: '#000000', 
        accent: '#916718',
        button: '#FF42A1',
    }, {
        title: 'classic elegance',
        background: '#FBF0DB',
        active: '#000000', 
        accent: '#827543',
        button: '#8C664C',
    }, {
        title: 'tropical paradise',
        backgroundDirection: 'radial',
        fromBackground: '#00CED1',
        toBackground: '#A0E7D3',
        active: '#000000', 
        accent: '#5e5e5e',
        button: '#1B5E20',
    }, {
        title: 'dark',
        background: '#1E1E1E',
        active: '#ffffff', 
        accent: '#8c8c8c',
        button: '#4D4D4D',
    }
];

export default (color: string): GalleryColor => galleryColorList.find(({ title }) => title === color)!;
