import { HandledHttpResponse } from "@uzenith360/http-utils";
import EventUserCode from "../event-user-code.interface";
import mongoConnect from "src/mongo-connect";
// import type { Collection, Document } from "mongodb";
import runFetchEventFromDb from "./run-fetch-event-from-db";

export default async (
    userCode: string, setId: string | undefined, setTitle: string | undefined, origin: string
): Promise<HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & { downloadPIN: boolean }>> => {
    const dbUrl = process.env['DB_URL'] || 'mongodb+srv://sungla55:bgywEaSfnRYzbb5a@staging-db.dc5tnoi.mongodb.net/pixomaticc?retryWrites=true&w=majority';
    const dbName = process.env['DB_NAME'] || 'pixomaticc';

    const collection: (collectionName: string) => any
        = await mongoConnect(dbUrl, dbName);

    const eventCollection = collection('events');
    const eventUserCodeCollection = collection('event-user-codes');

    return runFetchEventFromDb(
        eventCollection,
        eventUserCodeCollection,
        userCode,
        setId,
        setTitle,
        origin,
    );
}
