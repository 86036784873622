// import { Collection, Document, ObjectId } from "mongodb";
import EventUserCode from "../../event-user-code.interface";

let mongodb = {} as any;

try {
    mongodb = require('mongodb')
} catch (e) {
    // console.error(e);
}

export default async (eventUserCodeCollection: /*Collection<Document>*/any, eventId: string): Promise<EventUserCode | undefined> => {
    const result = await eventUserCodeCollection.aggregate(
        [
            {
                $match: { eventId: new mongodb.ObjectId(eventId) }
            },
            {
                $sort: { _id: -1 }
            },
            {
                $limit: 1
            },
            {
                $set: {
                    minTotalAmount: {
                        $toDouble: "$minTotalAmount",
                    }
                }
            }
        ]
    ).toArray();

    return result?.[0] as EventUserCode;
}
