// import { Collection, Document, ObjectId } from "mongodb";
import EventUserCode from "../event-user-code.interface";
import fetchEventUserCode from "./model/fetch-event-user-code";
import EventInterface from "./types/event.interface";
import fetchEventBySubomainAndSlug from "./model/fetch-event-by-subomain-and-slug";
import fetchEventUserCodeFetchByEventId from "./model/fetch-event-user-code-fetch-by-event-id";
import fetchEventByEventUserCodeAndSubdomain from "./model/fetch-event-by-event-user-code-and-subdomain";
import { HandledHttpResponse, httpStatus } from "@uzenith360/http-utils";
import { BankAccount, billingPackage, GallerySet, Photo, Photographer, sharingAction, sharingMode } from "@pixomaticc/ngx-ui";
import fetchGallerySets from "./model/fetch-gallery-sets";
import GalleryData from "./types/gallery-data.type";
import fetchGalleryData from "./model/fetch-gallery-data";
import EventPhotosUserMatchPhotoIdMatchesInterface from "./types/event-photos-user-match-photo-id-matches.interface";
import fillGalleryOptionsDefaultValues from "./utils/fill-gallery-options-default-values";
import getGalleryColorData from "./utils/get-gallery-color-data";
import getGalleryTypographyData from "./utils/get-gallery-typography-data";
import getPhotoMatches from "./utils/get-photo-matches";

let mongodb = {} as any;

try {
    mongodb = require('mongodb')
} catch (e) {
    // console.error(e);
}

const photosAmountKobo: number = 10000;

const PHOTO_MATCHES_PER_PAGE: number = 12;

export default async (
    eventCollection: /*Collection<Document>*/any,
    eventUserCodeCollection: /*Collection<Document>*/any,
    userCode: string, setId: string | undefined, setTitle: string | undefined, origin: string
): Promise<HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & { downloadPIN: boolean }>> => {
    const subdomain: string | undefined = ['http://127.0.0.1:4300', 'http://localhost:4000'].includes(origin)
        ? undefined
        : origin?.split('//')?.[1]?.split('.')?.[0];

    // const eventService: EventService = getEventServiceInstance();
    // const eventUserCodeService: EventUserCodeService = getEventUserCodeServiceInstance();

    const { gallery, eventUserCode }
        = await new Promise(
            async (
                resolve: (result: { eventUserCode?: EventUserCode, gallery?: EventInterface }) => void,
                reject: (error: unknown) => void
            ) => {
                try {
                    if (!subdomain || ['test', 'pixo'].includes(subdomain)) {
                        // if subdomain is not set, search with code which is the default
                        return resolve({ eventUserCode: await fetchEventUserCode(eventUserCodeCollection, userCode) });
                    } else {
                        // if subdomain is set, search photographer subdomain and slug combination
                        const gallery: EventInterface | undefined = await fetchEventBySubomainAndSlug(eventCollection, subdomain, userCode);

                        if (!!gallery) {
                            return resolve({ gallery, eventUserCode: await fetchEventUserCodeFetchByEventId(eventUserCodeCollection, gallery._id) });
                        } else {
                            // if not still found, search with subdomain and usercode
                            const result = await fetchEventByEventUserCodeAndSubdomain(eventCollection, subdomain, userCode);

                            if (!!result) {
                                const { eventUserCode, photographer, ...gallery } = result;

                                return resolve({ eventUserCode, gallery });
                            } else {
                                return resolve({});
                            }
                        }
                    }
                } catch (e) {
                    return reject(e);
                }
            },
        );

    // const eventUserCode: EventUserCodeInterface
    //   = await eventUserCodeService.fetch(userCode);

    if (!eventUserCode) {
        return {
            message: 'Broken link: Ask your photographer to resend the gallery link, and then you can try again',
            statusCode: httpStatus.NOT_FOUND,
            data: undefined,
        };
    }

    // fetch data and feed to getPhotoMatches and other place
    // eliminate external service calls

    // // EventUserCodeInterface OR EventInterface
    // ContactInterface
    // Photographer
    // photoMatches (limit 12)
    // photos from photoMatches PhotoId
    // offlinePayment { id: string, amount: number, bankAccount: BankAccount } 
    //  from { eventId, contactId, status: paymentStatus.pending } and photographer bank account (offline payment photographerId)

    let setIndex: number | undefined;

    //If there's an indicator that sets exists(e.g setId, or setTitle passed), assume gallery exists
    //If no indicator, then check if set exist
    const gallerySets: (GallerySet & { setIndex: number })[] | undefined = await fetchGallerySets(eventCollection, eventUserCode.eventId);
    const gallerySetsWithPhotos: (GallerySet & { setIndex: number })[] | undefined = gallerySets?.filter(({ photosCount }) => !!photosCount);

    if (!!gallerySetsWithPhotos?.length) {
        if (!!setTitle) {
            const { setIndex: index, _id }
                = gallerySetsWithPhotos.find(
                    ({ title }) =>
                        encodeURIComponent(String(title ?? '').replace(/[^a-zA-Z0-9]/g, '')) === setTitle
                )
                ?? (
                    setTitle === 'highlights'
                        ? gallerySetsWithPhotos.find(({ title }) => !title)
                        : undefined
                ) ?? gallerySetsWithPhotos[0];

            setIndex = index;
            setId = _id.toString();
        } else if (!!setId) {
            setIndex = gallerySetsWithPhotos.find(({ _id }) => new mongodb.ObjectId(_id).equals(setId))?.setIndex ?? 0;
        } else {
            const { _id, setIndex: index, photosCount } = gallerySetsWithPhotos[0]/*.find(({ photosCount }) => !!photosCount) ?? { photosCount: 0 }*/;

            if (!photosCount) {
                return {
                    message: 'No photos in gallery yet, check back later',
                    statusCode: httpStatus.NOT_FOUND,
                    data: undefined
                };
            }

            setIndex = index;
            setId = _id.toString();
        }
    }

    const galleryData: GalleryData | undefined = await fetchGalleryData(
        eventCollection,
        eventUserCode.eventId,
        eventUserCode.contactId,
        PHOTO_MATCHES_PER_PAGE,
        setIndex,
    );

    if (!galleryData) {
        return {
            message: 'There\'s a problem with this link, contact your photographer',
            statusCode: httpStatus.INTERNAL_SERVER_ERROR,
            data: undefined
        };
    }

    if (!galleryData?.photos?.length) {
        return {
            message: 'Broken link: Ask your photographer to send the updated gallery link, and then you can try again',
            statusCode: httpStatus.NOT_FOUND,
            data: undefined,
        };
    }

    const {
        bankAccount,
        contact,
        offlinePayment,
        photographerUser,
        photoMatches: galleryPhotoMatches,
        photographer: galleryPhotographer,
        currency,
        photos,
        coverPhoto,
        ...galleryEvent
    } = galleryData;

    const [
        user = { firstName: '', lastName: '', profilePhotoId: new mongodb.ObjectId() },
        {
            photographerId,
            amount,
            totalAmount,
            mode,
            action,
            isLandingPageGallery = false,
            deletedAt: galleryDeletedAt,
            name: title,
            slug,
            coverPhotoId,
            isOfflinePayment,
            galleryEventDate,
            isViewPhotosBeforePayment,
            isClientDownloaded,
            isClientReviewed,
            isClientSelected,
            isSkipClientReview,
        }
    ] = await Promise.all(
        [
            contact /*getContact(eventUserCode.contactId)*/,
            gallery ?? galleryEvent /*eventService.fetchById(eventUserCode.eventId)*/,
        ],
    );

    if (!!galleryDeletedAt) {
        return {
            message: 'Broken link: Ask your photographer to resend the gallery link, and then you can try again',
            statusCode: httpStatus.NOT_FOUND,
            data: undefined
        }
    }

    // If a photographer totalsize of photos is more than the plan,
    // disable their galleries from viewing like their sub expired
    if (
        (galleryPhotographer.totalUploadsSize ?? 0) >= {
            [billingPackage.essential]: 1e+11,
            [billingPackage.plus]: 2e+11,
            [billingPackage.pro]: 5e+11,
            [billingPackage.studio]: 1.024e+12,
            [billingPackage.basic]: 0
        }[(galleryPhotographer.billing ?? billingPackage.essential)]
        && !isLandingPageGallery
    ) {
        return {
            message: 'Notice: This gallery is unavailable due to a billing issue. Please contact your photographer to restore access.',
            statusCode: httpStatus.NOT_FOUND,
            data: undefined
        }
    }

    // If trial period has expired and photographer next billing date is more than 7days from current date
    // Show warning about billing period, to contact photographer to fix it (cos we'd keep paying when files are fetched)
    // Add a flag on galleries that are displayed on the home page, so this wont apply to them
    // You dont need to add the flag to the events service mongoose model, just add it in this codebase only

    const billingGracePeriod: number = 7 * 1000 * 60 * 60 * 24; // 7 days

    if (
        (
            Date.now() > (new Date(galleryPhotographer.createdAt).getTime() + (1000 * 60 * 60 * 24 * 15) + billingGracePeriod)
            && (
                !(!!galleryPhotographer.billing && !!galleryPhotographer.cycle && !!galleryPhotographer.nextBillingDate)
                || Date.now() > (new Date(galleryPhotographer.nextBillingDate ?? '').getTime() + billingGracePeriod)
            )
        ) && !isLandingPageGallery
    ) {
        return {
            message: 'Notice: This gallery is unavailable due to a billing issue. Please contact your photographer to restore access.',
            statusCode: httpStatus.NOT_FOUND,
            data: undefined
        }
    }

    const disallowViewMediaBeforePayment: boolean = !!totalAmount && !isViewPhotosBeforePayment;

    try {
        const [
            { cover, photoMatches, profilePhotos },
            isPhotosPublishedSinceLastMatch,
            photographer,
            offlinePaymentData,
        ] = await Promise.all(
            [
                getPhotoMatches(
                    // eventUserCode.contactId,
                    // eventUserCode.eventId, // turn the objectids to string cos of the .includes
                    photos.map((photo: Photo) => ({ ...photo, _id: photo._id.toString() })),
                    galleryPhotoMatches.photoIdMatches!
                        .map( // turn the objectids to string cos of the .includes
                            (photoIdMatch: EventPhotosUserMatchPhotoIdMatchesInterface) =>
                                ({ ...photoIdMatch, photoId: photoIdMatch.photoId.toString() })),
                    coverPhoto!,
                    action === sharingAction.COLLABORATION,
                    amount === 0 && !totalAmount,
                    disallowViewMediaBeforePayment,
                    user.profilePhotoId,
                    coverPhotoId
                ),
                mode === sharingMode.single
                    ? Promise.resolve(false) // TODO: work on this getIsPhotoPublishedSinceLastMatch to add it to the aggregate
                    : Promise.resolve(false)/*getIsPhotoPublishedSinceLastMatch(eventUserCode.eventId, eventUserCode.contactId)*/,
                { ...galleryPhotographer, user: photographerUser } /*fetchPhotographer(photographerId)*/,
                isOfflinePayment && offlinePayment
                    ? { ...offlinePayment, id: offlinePayment._id, bankAccount } /* getOfflinePaymentPendingBankAccount(eventUserCode.contactId, eventUserCode.eventId)*/
                    : Promise.resolve(null as { id: string, amount: number, bankAccount: BankAccount } | null)
            ],
        );

        const galleryDesignOptions = fillGalleryOptionsDefaultValues(
            {
                allowComments: galleryData.allowComments,
                allowFavorites: galleryData.allowFavorites,
                allowFrameRequests: galleryData.allowFrameRequests,
                allowPhotobookRequests: galleryData.allowPhotobookRequests,
                allowPrintRequests: galleryData.allowPrintRequests,
                allowUpsells: galleryData.allowUpsells,
                galleryCoverStyle: galleryData.galleryCoverStyle,
                downloadResolution: galleryData.downloadResolution,
                galleryColor: galleryData.galleryColor,
                galleryRoundness: galleryData.galleryRoundness,
                gallerySize: galleryData.gallerySize,
                gallerySpacing: galleryData.gallerySpacing,
                galleryTypography: galleryData.galleryTypography,
                downloadPIN: galleryData.downloadPIN,
                allowSingleFileDownloads: galleryData.allowSingleFileDownloads,
                allowFileDownloadEmailTracking: galleryData.allowFileDownloadEmailTracking,
            }
        );

        const eventUserCodeData = {
            ...eventUserCode,
            ...galleryDesignOptions,
            profilePhotos,
            photoMatches,
            photographerId,
            photographerName: photographer?.businessName || photographer?.user?.firstName!,
            photographerPhone: photographer?.user?.phone,
            highlightBusinessName: photographer?.isHighlightBusinessNameOnGallery,
            disablePreviewsWatermarkProtection: photographer?.isDisablePreviewsWatermarkProtection,
            name: `${user.firstName}${!!user.lastName ? ' ' + user.lastName : ''}`,
            idk: amount ?? photosAmountKobo, // amount in kobo for display
            kdi: totalAmount,
            sets: gallerySets?.filter(({ photosCount }) => !!photosCount),
            setId,
            title,
            slug,
            isLegacyDomain: !gallery,
            card: cover && { height: cover.height!, width: cover.width!, url: cover.lowResURL || cover.thumbURL || cover.midResURL || cover.highResURL! },
            cover: cover?.midResURL || cover?.thumbURL || cover?.highResURL,
            totalPhotos: !!gallerySets?.length
                ? gallerySets.reduce((acc, { photosCount }) => acc + photosCount, 0)
                : galleryPhotoMatches.photoIdMatchesCount,
            offlinePaymentId: offlinePaymentData?.id,
            bankAccount: offlinePaymentData?.bankAccount,
            offlinePaymentAmount: offlinePaymentData?.amount,
            isOfflinePayment,
            galleryEventDate,
            isClientDownloaded,
            isClientReviewed,
            isClientSelected,
            isSkipClientReview,
            isViewPhotosBeforePayment,
            isPhotosPublishedSinceLastMatch,
            mode: mode ?? sharingMode.multiple,
            action: action ?? sharingAction.DELIVERY,
            downloadPIN: !!galleryDesignOptions.downloadPIN, // Send boolean indicating whether to check for download PIN or not, dont send the actual download PIN!!!
            typographyData: getGalleryTypographyData(galleryDesignOptions.galleryTypography!),
            colorData: getGalleryColorData(galleryDesignOptions.galleryColor!),

            //New fields
            paymentInstructions: photographer.paymentInstructions,
            currency,
        };

        // also fetch the user photoURL, first name (For salutation: Hi Zenith,)
        return {
            message: '',
            statusCode: httpStatus.OK,
            data: eventUserCodeData as (EventUserCode & { downloadPIN: boolean }),
        };
    } catch (err: unknown) {
        console.error(err);

        return {
            message: 'Problem loading gallery, please give us another try',
            statusCode: httpStatus.INTERNAL_SERVER_ERROR,
            data: undefined
        };

        // if (err instanceof got.HTTPError && err.response.statusCode === 404) {
        //     return formatJSONResponse(
        //         {
        //             message: 'Broken link: Ask your photographer to send the updated gallery link, and then you can try again'
        //         },
        //         httpStatus.NOT_FOUND
        //     );
        // } else if (err instanceof got.RequestError && err.code === 'ETIMEDOUT') {
        //     return formatJSONResponse(
        //         {
        //             message: 'Gallery took a long time to load, please give us another try'
        //         },
        //         httpStatus.GATEWAY_TIMEOUT,
        //     );
        // } else {
        //     return formatJSONResponse(
        //         {
        //             message: 'Problem loading gallery, please give us another try'
        //         },
        //         httpStatus.INTERNAL_SERVER_ERROR,
        //     );
        // }
    }
}