import GalleryFont from "../../gallery-font.interface";
import galleryTypography from "../types/gallery-typography.enum";

const galleryTypographyUrlList: Record<galleryTypography, GalleryFont> = {
    [galleryTypography.sans]: {
        url: 'fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap',
        title: {
            family: '"Raleway", sans-serif',
            weight: '700',
            spacing: '0.05em',
            transform: 'uppercase',
        },
        subtitle: {
            family: '"Lato", sans-serif',
            weight: '400',
            spacing: '0.15em',
            transform: 'uppercase',
        }
    },
    [galleryTypography.serif]: {
        url: 'fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Spectral:wght@300;400;500&display=swap',
        title: {
            family: '"Cormorant", serif',
            weight: '300',
            spacing: '0.05em',
            transform: 'capitalize',
        },
        subtitle: {
            family: '"Spectral", serif',
            weight: '300',
            spacing: '0.15em',
            transform: 'uppercase',
        }
    },
    [galleryTypography.stylish]: {
        url: 'fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Tenor+Sans&display=swap',
        title: {
            family: '"Tenor Sans", sans-serif',
            weight: '400',
            spacing: '0.05em',
            transform: 'capitalize',
        },
        subtitle: {
            family: '"Red Hat Display", sans-serif',
            weight: '400',
            spacing: '0.15em',
            transform: 'uppercase',
        }
    },
    [galleryTypography.classic]: {
        url: 'fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap',
        title: {
            family: '"Playfair Display", serif',
            weight: '100',
            spacing: '0.05em',
            transform: 'capitalize',
        },
        subtitle: {
            family: '"Lora", serif',
            weight: '300',
            spacing: '0.15em',
            transform: 'uppercase',
        }
    },
    [galleryTypography.strong]: {
        url: 'fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&family=Syne:wght@400..800&display=swap',
        title: {
            family: '"Syne", sans-serif',
            weight: '700',
            spacing: '0.05em',
            transform: 'uppercase',
        },
        subtitle: {
            family: '"Red Hat Text", sans-serif',
            weight: '400',
            spacing: '0.15em',
            transform: 'uppercase',
        }
    },
    [galleryTypography.sleek]: {
        url: 'fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quattrocento:wght@400;700&display=swap',
        title: {
            family: '"Montserrat", sans-serif',
            weight: '300',
            spacing: '0.05em',
            transform: 'uppercase',
        },
        subtitle: {
            family: '"Quattrocento", serif',
            weight: '400',
            spacing: '0.15em',
            transform: 'uppercase',
        }
    },
}

export default (name: galleryTypography) => galleryTypographyUrlList[name];
