// import { Collection, Document, ObjectId } from "mongodb";
import { GallerySet } from "@pixomaticc/ngx-ui";

let mongodb = {} as any;

try {
    mongodb = require('mongodb')
} catch (e) {
    // console.error(e);
}

export default async (eventCollection: /*Collection<Document>*/any, eventId: string): Promise<(GallerySet & { setIndex: number })[] | undefined> => {
    const result = await eventCollection.aggregate(
        [
            {
                $match: {
                    _id: new mongodb.ObjectId(eventId)
                }
            },
            {
                $lookup: {
                    from: "event-photos-user-matches",
                    localField: "_id",
                    foreignField: "eventId",
                    as: "result"
                }
            },
            {
                $replaceRoot: {
                    newRoot: {
                        $first: "$result"
                    }
                }
            },
            {
                $match: {
                    sets: {
                        $exists: true
                    }
                }
            },
            {
                $lookup: {
                    from: "event-photos",
                    localField: "sets.eventPhotoId",
                    foreignField: "_id",
                    as: "galleries"
                }
            },
            {
                $project: {
                    _id: 0,
                    galleries: {
                        $map: {
                            input: "$galleries",
                            as: "gallery",
                            in: {
                                _id: "$$gallery._id",
                                title: "$$gallery.title",
                                index: "$$gallery.index"
                            }
                        }
                    },
                    sets: {
                        $map: {
                            input: "$sets",
                            as: "set",
                            in: {
                                _id: "$$set.eventPhotoId",
                                photosCount: { $size: "$$set.filesData" }
                            }
                        }
                    }
                }
            },
            {
                $set: {
                    galleries: {
                        $sortArray: {
                            input: "$galleries",
                            sortBy: {
                                index: 1
                            }
                        }
                    }
                }
            }
        ]
    ).toArray();

    const { sets, galleries }: { sets: { _id: typeof mongodb.ObjectId, photosCount: number }[], galleries: { _id: typeof mongodb.ObjectId, index?: number, title?: string }[] } = result?.[0] ?? {} as { sets: { _id: typeof mongodb.ObjectId, photosCount: number }[], galleries: { _id: typeof mongodb.ObjectId, index?: number, title?: string }[] };

    if (!galleries || !sets) {
        return undefined;
    }

    const gallerySets: (GallerySet & { setIndex: number })[] = [];

    for (const { _id, title } of galleries) {
        const setIndex: number = sets.findIndex(({ _id: setId }) => new mongodb.ObjectId(_id).equals(setId));

        if (setIndex === -1) {
            continue;
        }

        gallerySets.push(
            {
                title,
                setIndex,
                _id: _id.toHexString(),
                photosCount: sets[setIndex].photosCount,
            }
        );
    }


    return gallerySets;
}
