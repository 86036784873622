// import { Collection, Document } from "mongodb";
import Utils from '@uzenith360/utils';
import { Photographer } from "@pixomaticc/ngx-ui";
import EventUserCode from "../../event-user-code.interface";
import EventInterface from "../types/event.interface";

export default async (eventCollection: /*Collection<Document>*/any, subdomain: string, galleryCode: string): Promise<EventInterface & { photographer: Photographer, eventUserCode: EventUserCode} | undefined> => {
    const result = await eventCollection.aggregate(
        [
            {
                $lookup: {
                    from: "photographers",
                    localField: "photographerId",
                    foreignField: "_id",
                    pipeline: [
                        {
                            $match: { subdomain: Utils.trimAndLowercase(subdomain) },
                        },
                    ],
                    as: "photographer",
                },
            },
            {
                $match: {
                    photographer: {
                        // $exists: true,
                        // $type: "array",
                        $ne: [],
                    },
                },
            },
            {
                $lookup: {
                    from: "event-user-codes",
                    localField: "_id",
                    foreignField: "eventId",
                    pipeline: [
                        {
                            $match: { code: Utils.trimAndUppercase(galleryCode) },
                        },
                        {
                            $set: {
                                minTotalAmount: { $toDouble: '$minTotalAmount' }
                            }
                        },
                    ],
                    as: "eventUserCode",
                },
            },
            {
                $match: {
                    eventUserCode: {
                        // $exists: true,
                        // $type: "array",
                        $ne: [],
                    },
                },
            },
            { $sort: { _id: -1 } },
            { $limit: 1 },
            {
                $set: {
                    amount: { $toDouble: '$amount' },
                    totalAmount: { $toDouble: '$totalAmount' },
                    photographer: {
                        $first: "$photographer",
                    },
                    eventUserCode: {
                        $first: "$eventUserCode",
                    },
                },
            },
        ]
    ).toArray();

    return result?.[0] as EventInterface & { photographer: Photographer, eventUserCode: EventUserCode };
}
