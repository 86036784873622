// import { Collection, Document } from "mongodb";
import Utils from '@uzenith360/utils';
import EventUserCode from "../../event-user-code.interface";

export default async (eventUserCodeCollection: /*Collection<Document>*/any, galleryCode: string): Promise<EventUserCode | undefined> => {
    const result = await eventUserCodeCollection.aggregate(
        [
            {
                $match: { code: Utils.trimAndUppercase(galleryCode) }
            },
            { 
                $set: {
                    minTotalAmount: {
                        $toDouble: "$minTotalAmount",
                    }
                }
            }
        ]
    ).toArray();

    return result?.[0] as EventUserCode;
}
