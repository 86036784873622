import { coverStyle, galleryDesignSize, downloadResolution, galleryOption } from "@pixomaticc/ngx-ui";
import EventInterface from "../types/event.interface";
import galleryTypography from "../types/gallery-typography.enum";


const fieldDefaultValues: { field: string, defaultValue: string | boolean | undefined }[] = [
    { field: 'galleryColor', defaultValue: 'light' },
    { field: 'galleryCoverStyle', defaultValue: coverStyle.center },
    { field: 'gallerySize', defaultValue: galleryDesignSize.regular },
    { field: 'gallerySpacing', defaultValue: galleryDesignSize.regular },
    { field: 'galleryRoundness', defaultValue: galleryDesignSize.regular },
    { field: 'galleryTypography', defaultValue: galleryTypography.sans },
    { field: 'downloadResolution', defaultValue: downloadResolution.highRes },
    { field: 'allowFavorites', defaultValue: true },
    { field: 'allowComments', defaultValue: true },
    { field: 'allowUpsells', defaultValue: true },
    { field: 'allowFrameRequests', defaultValue: true },
    { field: 'allowPrintRequests', defaultValue: true },
    { field: 'allowPhotobookRequests', defaultValue: true },
    { field: 'downloadPIN', defaultValue: undefined },
    { field: 'allowSingleFileDownloads', defaultValue: true },
    { field: 'allowFileDownloadEmailTracking', defaultValue: false },
];

export default <T extends { [key: string]: string | boolean | undefined }>(event: T extends Pick<EventInterface, galleryOption> ? T : never): T => {
    const newEvent: T = { ...event };

    for (const { field, defaultValue } of fieldDefaultValues) {
        if (newEvent[field] === undefined) {
            //@ts-ignore
            newEvent[field] = defaultValue;
        }
    }

    return newEvent;
}
