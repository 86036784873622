import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import EventUserCode from './event-user-code.interface';
import { Inject, Injectable, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { EventPhotosService } from './event-photos.service';
import { firstValueFrom, Observable, of } from 'rxjs';
import Utils from "@uzenith360/utils";
import { ORIGIN } from '../tokens';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import fetchEventFromDb from './fetch-event-from-db';
import { HandledHttpResponse } from '@uzenith360/http-utils';
import galleryDataKey from './fetch-event-from-db/gallery-data.key';

@Injectable({
  providedIn: 'root'
})
export class EventPhotosResolver {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ORIGIN) @Optional() private readonly origin: string,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly eventPhotosService: EventPhotosService,
    private readonly transferState: TransferState,
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<{ data?: Omit<EventUserCode, 'downloadPIN'> & { downloadPIN: boolean } & { galleryPreviewSection: 'gallery' | 'cover' | undefined }, message?: string | undefined, statusCode: number }> {
    const [i, t, d] = [route.queryParams['i'], route.queryParams['t'], route.queryParams['d']];

    const { message, statusCode, data } = await firstValueFrom(
      !!i && !!t && !!d
        ? this.eventPhotosService.fetchEventForGalleryPreview(i, t, d)
        : await this.fetchEvent(route.params['code'], /*this.activeSetId*/ undefined, route.params['title'], this.origin ?? this.document.location.origin)
    );

    if (statusCode === 200 && !!data) {
      return {
        statusCode,
        data: {
          ...data,
          galleryPreviewSection: t as 'cover' | 'gallery' | undefined,
        }
      };
    } else {
      return { message, statusCode }
    }
  }

  private async fetchEvent(
    userCode: string, setId: string | undefined, setTitle: string | undefined, origin: string | undefined
  ): Promise<Observable<HandledHttpResponse<Omit<EventUserCode, 'downloadPIN'> & { downloadPIN: boolean }>>> {
    const defaultErrorResponse = { message: 'Problem loading gallery, please try again', statusCode: 500, data: undefined };

    if (isPlatformServer(this.platformId)) { 
      const result: HandledHttpResponse<Omit<EventUserCode, "downloadPIN"> & { downloadPIN: boolean; }>
        = await fetchEventFromDb(userCode, setId, setTitle, origin ?? '')
          .catch(
            (err) => {
              console.error(err);

              return defaultErrorResponse;
            }
          );

      const processedResult: HandledHttpResponse<Omit<EventUserCode, "downloadPIN"> & { downloadPIN: boolean; }> = {
        ...result,
        data: {
          ...result.data!,
          offlinePaymentAmount: result.data?.offlinePaymentAmount
            && Utils.roundOff2Dp(result.data.offlinePaymentAmount / 100),
        }
      };

      this.transferState.set(galleryDataKey, processedResult);

      return of(processedResult);
    } else if (isPlatformBrowser(this.platformId)) {
      // this.eventPhotosService.fetchEvent(userCode, setId, setTitle, origin);

      return of(this.transferState.get(galleryDataKey, defaultErrorResponse));
    } else {
      console.error('Unexpected platform');

      return of(defaultErrorResponse);
    }
  }
}
