import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EventPhotosResolver } from './event-photos/event-photos.resolver';
import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
// import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

// const hostname = window.location.hostname;

// const routes: Routes = [
//   { path: 'error', component: PageErrorComponent },
//   ...hostname !== '127.0.0.1' && hostname.split('.').length > 2 && !['test'].includes(hostname.split('.')?.[0])
//     ? [
//       { path: ':code', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//       { path: ':code/:title', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//     ]
//     : [
//       { path: ':code', component: PhotoEventsComponent },
//       { path: 'p/:code', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//       { path: 'p/:code/:title', loadChildren: () => import('./event-photos/event-photos.module').then(m => m.EventPhotosModule) },
//     ],
//   { path: '**', component: PageNotFoundComponent }
// ];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  providers: [
    // provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
  ],
  imports: [
    RouterModule.forRoot(
      [
        { path: 'p/:code', loadComponent: () => import('./event-photos/event-photos.component').then(m => m.EventPhotosComponent), resolve: { data: EventPhotosResolver } },
        { path: 'p/:code/:title', loadComponent: () => import('./event-photos/event-photos.component').then(m => m.EventPhotosComponent), resolve: { data: EventPhotosResolver } },
        { path: ':code', loadComponent: () => import('./event-photos/event-photos.component').then(m => m.EventPhotosComponent), resolve: { data: EventPhotosResolver } },
        { path: ':code/:title', loadComponent: () => import('./event-photos/event-photos.component').then(m => m.EventPhotosComponent), resolve: { data: EventPhotosResolver } },
      ]
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
