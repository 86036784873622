// import { Collection, Document, MongoClient, ServerApiVersion } from "mongodb";
let mongodb = {} as any;

try {
    mongodb = require('mongodb')
} catch (e) {
    // console.error(e);
}

let connection: /*MongoClient*/any;

// Replace the placeholder with your Atlas connection string
export default async (uri: string, dbName: string): Promise<(collectionName: string) => /*Collection<Document>*/any> => {
    const connection: /*MongoClient*/any = await openConnection(uri);

    return (collectionName: string) => connection.db(dbName).collection(collectionName);
}

export const openConnection = async (uri: string, forceConnection: boolean = false): Promise</*MongoClient*/any> => {
    if (!connection || forceConnection) {
        // Close previously open connections, if any
        await closeConnection()?.catch(console.error);

        // Create a MongoClient with a MongoClientOptions object to set the Stable API version
        const client = new mongodb.MongoClient(
            uri,
            {
                serverApi: {
                    version: mongodb.ServerApiVersion.v1,
                    strict: true,
                    deprecationErrors: true,
                },
                // useNewUrlParser: true,
                // useUnifiedTopology: true,
                maxPoolSize: 20, // Configure pool size to optimize resource usage
            }
        );

        // Connect the client to the server (optional starting in v4.7)
        connection = await client.connect();

        // Send a ping to confirm a successful connection
        // await connection.db(dbName).command({ ping: 1 });
        // connection.db(dbName).collection('attendees').find({}).toArray()

        // connection.db(dbName).collection('attendees').aggregate([], {})

        // connection.db(dbName).collection('attendees').insertOne({})

        // connection.db(dbName).collection('attendees').updateMany({}, {})


        // console.log("Pinged your deployment. You successfully connected to MongoDB!");
    }

    return connection;
}

export const closeConnection = () => connection?.close();

try {
    // Handle graceful shutdown
    process.on(
        'SIGINT',
        async () => {
            await closeConnection()?.catch(console.error);

            process.exit(0);
        }
    );

    process.on(
        'SIGTERM',
        async () => {
            await closeConnection()?.catch(console.error);

            process.exit(0);
        }
    );
} catch (e) {
    // console.error(e);
}
